<template>
  <div class="container-fluid">
    <Header />
    <div
      class="mx-auto mt-1 content-outer-container adjust-point"
      :class="{ 'content-dashboard': $store.state.isDashboard }"
    >
      <div class="mx-auto">
        <div class="wrapper-ifrem custom-wrapper-ifrem">
          <div class="row paj-custom-row">
            <div
              v-show="!isUploadSuccess"
              class="col-xl-12 col-lg-12 col-md-12 pb-4 wrapper-dashboard custom-wrapper-dashboard"
            >
              <div
                class="card-custom card-stretch gutter-b panel panel-default title-wrapper"
              >
                <div class="text-title-point-adjustment">
                  {{ lbl['import-tab-label'] }}
                </div>
                <notice
                  v-if="isBeeBanefit"
                  :messege="beeBenefitNoticeMessage"
                  icon="red_notice"
                />
              </div>
            </div>
            <div
              v-show="!isUploadSuccess"
              class="col-xl-12 col-lg-12 col-md-12 wrapper-point-adjustment"
            >
              <div class="page-title">
                <img src="@/assets/images/file_plus.svg" alt="file icon" />
                {{ lbl['spending-adjustment-page-title'] }}
              </div>
              <div class="d-flex wrapper-select-header">
                <div
                  class="select-header"
                  :class="{ active: tabHeader === 'ImportSpending' }"
                  @click="changeTab('ImportSpending')"
                >
                  {{ lbl['import-adjust-spending-label'] }}
                </div>
                <div
                  class="select-header select-hd-right"
                  :class="{ active: tabHeader !== 'ImportSpending' }"
                  @click="changeTab('ActivityLog')"
                >
                  {{ lbl['tab-import-log-mnu'] }}
                </div>
              </div>
              <div class="wrapper-content">
                <div
                  v-if="tabHeader === 'ImportSpending'"
                  class="around-content-import"
                >
                  <div class="wrapper-radio-height">
                    <div>
                      <a-radio-group
                        v-model="importValue"
                        class="wrapper-radio-custom"
                      >
                        <a-radio
                          :value="1"
                          class="radio-gender"
                          @click="tagAnalytic('import_by_mobile_number')"
                        >
                          {{ lbl['import-by-mobile-number'] }}
                        </a-radio>
                        <a-radio
                          :value="2"
                          class="radio-gender"
                          @click="tagAnalytic('import_by_customer_id')"
                        >
                          {{ lbl['import-by-customer-id'] }}
                        </a-radio>
                      </a-radio-group>
                    </div>
                  </div>

                  <div v-if="!isProgress">
                    <drop class="wrapper-upload-excel-file" @drop="handleDrop">
                      <div class="wrapper-sub-upload-excel-file">
                        <img
                          :src="require('@/assets/images/cloud_upload@2x.png')"
                          alt=""
                          class="img-cloud-upload"
                          :draggable="false"
                        />
                        <div class="text-1">
                          {{ lbl['drag-drop-file-here'] }}
                        </div>
                        <div class="text-2">
                          {{ lbl['file-format-excel-new-design'] }}
                        </div>

                        <a-button
                          class="btn-Upload-excel"
                          @click="clickUpload()"
                        >
                          <img
                            :src="require('@/assets/images/upload@2x.png')"
                            alt=""
                            :draggable="false"
                          />
                          <span>{{ lbl['upload-excel-file'] }}</span>
                        </a-button>
                        <input
                          ref="file"
                          type="file"
                          class="d-none"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          @change="handleChange"
                        />
                      </div>
                    </drop>
                  </div>
                  <div v-else class="wrapper-upload-excel-file">
                    <div class="w-50">
                      <div class="">
                        {{ lbl['uploading'] }}
                        <strong>{{ percentProgress }}%</strong>
                      </div>
                      <a-progress
                        :percent="percentProgress"
                        :show-info="false"
                        status="active"
                      />
                    </div>
                  </div>
                  <div class="text-download-tem">
                    <a
                      class="txt-dwnl-temp"
                      @click="tagAnalytic('download_file_template')"
                      :href="
                        importValue == 1
                          ? adjust_mobile_number_template
                          : adjust_template
                      "
                      download
                    >
                      <img
                        :src="require('@/assets/images/Download@2x.png')"
                        alt=""
                      />
                      {{ lbl['burn-download-temp'] }}
                    </a>
                  </div>
                </div>
                <div
                  v-if="tabHeader === 'ActivityLog'"
                  class="around-content-activity"
                >
                  <ActivityLog />
                </div>
              </div>
            </div>

            <!-- Validate Adjust File -->
            <div
              v-show="isUploadSuccess"
              class="col-xl-12 col-lg-12 col-md-12 custom-wrapper-dashboard"
            >
              <div class="title-wrapper">
                <div class="d-flex title-header title">
                  <div @click="btnBackUpload()">
                    <img
                      class="button-arrow-back mr-3"
                      src="@/assets/images/userlevel/arrow_back.svg"
                      alt=""
                    />
                  </div>
                  {{ lbl['import-spending'] }}
                </div>
                <notice
                  v-if="isBeeBanefit"
                  :messege="beeBenefitNoticeMessage"
                  icon="red_notice"
                />
              </div>

              <div class="box-validate-file mt-4">
                <div v-if="countSuccess > 0" class="row">
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="show-validate">
                      <div class="d-flex align-center">
                        <img
                          class="mr-2 img-import"
                          :src="
                            require('@/assets/images/importfile/done-circle.svg')
                          "
                          alt=""
                        />
                        <strong>{{ lbl['import-log-import-success'] }}</strong>
                      </div>

                      <div class="row mt-2">
                        <div
                          class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                        >
                          <div class="d-flex align-center">
                            <strong class="mr-2">{{ countSuccess }} </strong>
                            <div>{{ lbl['items'] }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="show-validate">
                      <div class="d-flex align-center">
                        <img
                          class="mr-2 img-import"
                          :src="
                            require('@/assets/images/importfile/warning-circle.svg')
                          "
                          alt=""
                        />
                        <strong>{{ lbl['import-failed'] }}</strong>
                      </div>
                      <div class="row mt-2">
                        <div
                          class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                        >
                          <div class="d-flex align-center">
                            <strong class="mr-2">{{ countFailed }} </strong>
                            <div>{{ lbl['items'] }}</div>
                          </div>
                        </div>
                        <div
                          class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 text-right"
                        >
                          <a-button
                            type="primary"
                            :disabled="countFailed <= 0"
                            @click="exprotFailed"
                          >
                            <img
                              :src="
                                require('@/assets/images/importfile/download.svg')
                              "
                              alt=""
                              class="mr-2"
                            />
                            {{
                              lbl['download-file'] +
                              ' (' +
                              countFailed +
                              ' ' +
                              lbl['items'] +
                              ')'
                            }}
                          </a-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="row">
                  <div class="col-12">
                    <div class="show-validate">
                      <div class="d-flex align-center">
                        <img
                          class="mr-2 img-import"
                          :src="
                            require('@/assets/images/importfile/warning-circle.svg')
                          "
                          alt=""
                        />
                        <strong>{{ lbl['import-failed'] }}</strong>
                      </div>
                      <div class="row mt-2">
                        <div class="col-12">
                          <div class="d-flex align-center">
                            <strong class="mr-2">{{ countFailed }} </strong>
                            <div>{{ lbl['items'] }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="title">
                {{ lbl['import-list'] }}
                <a-badge
                  :count="countTotal"
                  :overflow-count="9999"
                  :number-style="{
                    backgroundColor: '#fff',
                    color: '#999',
                    boxShadow: '0 0 0 1px #d9d9d9 inset',
                  }"
                />
              </div>

              <div class="box-validate-table mt-2">
                <a-table
                  :columns="importValue == 2 ? columns : columnsNumber"
                  :data-source="data"
                  rowKey="RowKey"
                  :pagination="{
                    pageSize: defaultPageSize,
                    defaultCurrent: 1,
                    hideOnSinglePage: false,
                  }"
                >
                  <template slot="isdata" slot-scope="text">
                    {{ text ? text : '' }}
                  </template>
                  <template slot="status" slot-scope="text">
                    <a-tag v-if="text == null" color="green">
                      <span class="d-flex align-center">
                        <a-icon type="check-circle" class="mr-1" />
                        {{ lbl['import-log-import-success'] }}
                      </span>
                    </a-tag>
                    <a-tag v-else color="red">
                      <span class="d-flex align-center">
                        <a-icon type="close-circle" class="mr-1" />
                        {{ lbl['import-failed'] }}
                      </span>
                    </a-tag>
                  </template>
                  <template slot="point" slot-scope="text">
                    <span v-if="text != null" class="d-flex justify-end">
                      {{ text }}
                    </span>
                    <span v-else class="d-flex align-center justify-end">
                      <a-icon type="close" class="mr-1" />
                      <span class="color-red">{{ lbl['invalid-format'] }}</span>
                    </span>
                  </template>
                  <template slot="remark" slot-scope="text">
                    <span
                      v-if="
                        text == 'UserId is not found' ||
                        text == 'User is not found'
                      "
                    >
                      {{ lbl['user-is-not-found'] }}
                    </span>
                    <span v-else>
                      {{ text ? text : '' }}
                    </span>
                  </template>
                </a-table>
              </div>

              <hr />
              <div class="row">
                <div class="col-12 d-flex justify-center">
                  <a-button
                    class="btn-submit px-5 mr-1"
                    @click="btnBackUpload()"
                  >
                    <span>{{ lbl['btn_back'] }}</span>
                  </a-button>
                  <a-button
                    type="primary"
                    class="btn-submit px-5 ml-1"
                    :disabled="countSuccess <= 0"
                    @click="handleConfirmSubmit()"
                  >
                    <span>{{ lbl['btn-submit'] }}</span>
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmModal
      :isOpenModal="confirmModalSetting.isOpenModal"
      :modalType="confirmModalSetting.modalType"
      :title="confirmModalSetting.title"
      :details="confirmModalSetting.details"
      :backto="confirmModalSetting.backto"
      :onConfirm="confirmModalSetting.onConfirm"
      :onCancel="confirmModalSetting.onCancel"
    />
    <AlertModal
      :isOpenModal="alertModalSetting.isOpenModal"
      :modalType="alertModalSetting.modalType"
      :title="alertModalSetting.title"
      :details="alertModalSetting.details"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
import AlertModal from '@/components/Modal/Component/alertmodal2'
import ConfirmModal from '@/components/Modal/Component/confirmmodal2'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import BzbsImport from '@/core/Import/service/BzbsImport'
import Header from '@/components/Layout/Header'
import ActivityLog from '@/module/ImportBatchPoint/ActivitySpendingLog'
import { Drop } from 'vue-drag-drop'
import _ from 'lodash'
import XLSX from 'xlsx'
import moment from 'moment'
import notice from '@/module/ImportUser/components/notice.vue'

export default {
  name: 'AdjustPoint',
  mixins: [Mixin],
  components: {
    Header,
    ActivityLog,
    Drop,
    AlertModal,
    ConfirmModal,
    notice,
  },
  data: function () {
    return {
      fileReader: [],
      showModal: false,
      isAlreadyPoint: false,
      isAlreadyStamp: false,
      allowImportSpending: true,
      campaignmode: '',
      dispfile: false,
      excelFileName: '',
      importValue: 1,
      tabHeader: 'ImportSpending',
      getFileUpdate: null,
      currentSerial: 1,
      confirmModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        backto: '',
        onConfirm: null,
        onCancel: null,
        isOpenModal: false,
      },
      alertModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        isOpenModal: false,
      },
      rowKey: null,
      partitionKey: null,
      loginType: null,
      appId: null,
      liffId: null,
      liffLanding: null,
      isProgress: false,
      percentProgress: 0,
      timeinterval: null,
      isUploadSuccess: false,
      countSuccess: 0,
      countFailed: 0,
      countTotal: 0,
      columns: [
        {
          title: 'Status',
          dataIndex: 'Message',
          key: 'import-log-status-header',
          align: 'left',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: 'User ID',
          dataIndex: 'UserId',
          key: 'view-code-user-id',
          align: 'left',
          scopedSlots: { customRender: 'isdata' },
        },
        {
          title: 'Name',
          dataIndex: 'FirstName',
          key: 'user-permissions-name',
          align: 'left',
          scopedSlots: { customRender: 'isdata' },
        },
        {
          title: 'Last Name',
          dataIndex: 'LastName',
          key: 'user-permissions-lastname',
          align: 'left',
          scopedSlots: { customRender: 'isdata' },
        },
        {
          title: 'Spending',
          dataIndex: 'StrSpendingAmount',
          key: 'spending-unit',
          scopedSlots: { customRender: 'point' },
        },
        {
          title: 'Description',
          dataIndex: 'Description',
          key: 'import-log-desc-header',
          align: 'left',
          scopedSlots: { customRender: 'isdata' },
        },
        {
          title: 'Remark',
          dataIndex: 'Message',
          key: 'remark',
          align: 'left',
          scopedSlots: { customRender: 'remark' },
        },
      ],
      columnsNumber: [
        {
          title: 'Status',
          dataIndex: 'Message',
          key: 'import-log-status-header',
          align: 'left',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: 'Mobile Number',
          dataIndex: 'ContactNumber',
          key: 'manage-usr-contact-number',
          align: 'left',
          scopedSlots: { customRender: 'isdata' },
        },
        {
          title: 'Spending',
          dataIndex: 'StrSpendingAmount',
          key: 'spending-unit',
          width: '15%',
          scopedSlots: { customRender: 'point' },
        },
        {
          title: 'Description',
          dataIndex: 'Description',
          key: 'import-log-desc-header',
          align: 'left',
          scopedSlots: { customRender: 'isdata' },
        },
        {
          title: 'Remark',
          dataIndex: 'Message',
          key: 'remark',
          align: 'left',
          scopedSlots: { customRender: 'remark' },
        },
      ],
      data: [],
      dataFailed: [],
      defaultPageSize: 10,
      editableData: '',
      beeBenefitNoticeMessage: '',
      isBeeBanefit: false,
      adjust_mobile_number_template:
        Vue.bzbsConfig.bzbsBlobUrl +
        '/config/crmplus/ImportUserTemplate/import_adjust_spending_mobile_number_template.xlsx',
      adjust_template:
        Vue.bzbsConfig.bzbsBlobUrl +
        '/config/crmplus/ImportUserTemplate/import_adjust_spending_template.xlsx',
    }
  },
  computed: {
    isDisable() {
      if (this.loginType == 'Line') {
        if (this.appId && (this.liffLanding || this.liffId)) {
          return false
        } else {
          return true
        }
      } else {
        if (this.appId) {
          return false
        } else {
          return true
        }
      }
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'importSpending' })
  },
  created() {
    Account.bzbsAnalyticTracking(
      'point_adjustment_page',
      'point_adjustment',
      'view_point_adjustment',
      'on view',
    )
    this.handleFooter(true)
    var crmPackage = Account.getCachePackage()
    if (crmPackage && crmPackage.PackageId) {
      this.isBeeBanefit = crmPackage.PackageId.includes('benefit')
    }
    this.beeBenefitNoticeMessage =
      this.lbl['bee-benefit-import-point-warning-messege']
    if (this.$route.params.data) {
      this.isUploadSuccess = true
    }
    this.percentProgress = 0
    this.timeinterval = null
    this.isProgress = false
    this.setLocale()
    this.getCRMPlusProfile().then(result => {
      this.loginType = result.LoginType
      this.appId = result.AppId
      this.liffId = result.LiffId
      this.liffLanding = result.LiffLanding
      if (typeof result.IsAlreadyPoint == 'boolean') {
        this.isAlreadyPoint = result.IsAlreadyPoint
      }
      if (typeof result.IsAlreadyStamp == 'boolean') {
        this.isAlreadyStamp = result.IsAlreadyStamp
      }
      if (typeof result.AllowImportSpending == 'boolean') {
        this.allowImportSpending = result.AllowImportSpending
      }
      if (this.isAlreadyPoint) {
        this.campaignmode = this.lbl['import-mode-point']
      } else {
        this.campaignmode = this.lbl['import-mode-stamp']
      }
    })
    if (
      this.$router.currentRoute.path.includes('/Import/AdjustSpending/') &&
      !this.data.length
    ) {
      window.location = '/Import/AdjustSpending'
    }
  },
  methods: {
    setLocale() {
      _.forEach(this.columns, column => {
        column.title = this.lbl[column.key]
      })

      _.forEach(this.columnsNumber, column => {
        column.title = this.lbl[column.key]
      })
    },
    getCRMPlusProfile() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('apiGetAppCategoryList error', error)
            this.alertModalAction(
              this.lbl['alert-box-error-header'],
              error.response.data.error.message,
              'error',
              true,
            )
            resolve(error)
          })
      })
    },
    tagAnalytic(type) {
      Account.bzbsAnalyticTracking(
        'point_adjustment_page',
        'point_adjustment',
        'click_' + type,
        'on click',
      )
    },
    calcelCallApi() {
      this.getFileUpdate = null
      this.$refs.file.value = null
    },
    checkValidate(file) {
      this.clearerror()
      var isType = true
      const isSheet =
        file.type ==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      const isExcel = file.type == 'application/vnd.ms-excel'
      if (!isSheet && !isExcel) {
        this.alertModalAction(
          this.lbl['alert-box-error-header'],
          '',
          'error',
          true,
        )
        isType = false
      }
      return isType
    },
    removeFileUpload() {
      this.$refs.file.value = null
    },
    clearerror() {
      this.dispfile = false
      for (let key in this.alertInfo) {
        this.alertInfo[key] = false
      }
    },
    confirmModal() {
      this.$confirm({
        title: this.lbl['plan-reward-point-burn-manage-save-confirm-title'],
        content: this.lbl['adjust-confirm-text'],
        centered: true,
        autoFocusButton: null,
        okText: this.lbl['confirm-box-confirm-button'],
        cancelText: this.lbl['confirm-box-cancel-button'],
        closable: true,
        onOk: () => {
          this.handleSubmit()
        },
      })
    },
    changeTab(active) {
      this.tabHeader = active
      if (active == 'ImportSpending') {
        Account.bzbsAnalyticTracking(
          'point_adjustment_page',
          'point_adjustment',
          'view_import_point',
          'on view',
        )
        Account.bzbsAnalyticTracking(
          'point_adjustment_page',
          'point_adjustment',
          'click_import_point',
          'on click',
        )
      } else {
        Account.bzbsAnalyticTracking(
          'point_adjustment_page',
          'point_adjustment',
          'view_activitylog',
          'on view',
        )
        Account.bzbsAnalyticTracking(
          'point_adjustment_page',
          'point_adjustment',
          'click_activitylog',
          'on click',
        )
      }
    },
    confirmModalAction(
      title,
      details,
      modalType,
      backto,
      isOpenModal,
      onConfirm,
      onCancel,
    ) {
      this.confirmModalSetting.title = title
      this.confirmModalSetting.details = details
      this.confirmModalSetting.modalType = modalType
      this.confirmModalSetting.isOpenModal = isOpenModal
      this.confirmModalSetting.backto = backto
      this.confirmModalSetting.onConfirm = onConfirm
      this.confirmModalSetting.onCancel = onCancel
    },
    alertModalAction(
      title,
      details,
      modalType,
      isOpenModal,
      desciption,
      isLog,
    ) {
      this.alertModalSetting.title = title
      this.alertModalSetting.details = details
      this.alertModalSetting.modalType = modalType
      this.alertModalSetting.isOpenModal = isOpenModal
      this.alertModalSetting.desciption = desciption
      if (modalType == 'success' && isOpenModal) {
        if (isLog) {
          setTimeout(() => {
            this.alertModalSetting.isOpenModal = false
            this.percentProgress = 0
            this.timeinterval = null
            this.isProgress = false
            this.isUploadSuccess = false
            this.countSuccess = 0
            this.countFailed = 0
            this.countTotal = 0
            this.data = []
            this.editableData = null
            if (this.isBeeBanefit) {
              this.$router.go()
            } else {
              this.tabHeader = 'ActivityLog'
            }
          }, 3000)
        } else {
          setTimeout(() => {
            this.alertModalSetting.isOpenModal = false
            if (this.isBeeBanefit) {
              this.$router.go()
            }
          }, 3000)
        }
      } else {
        if (isOpenModal)
          setTimeout(() => {
            location.reload()
            this.alertModalSetting.isOpenModal = false
          }, 3000)
      }
    },

    //#region Upload
    handleDrop(data, event) {
      console.log('data :: ', data)
      event.preventDefault()
      if (this.checkValidate(event.dataTransfer.files[0])) {
        this.getFileUpdate = event.dataTransfer.files[0]
        this.handleValidateSpending()
      }
    },
    clickUpload() {
      Account.bzbsAnalyticTracking(
        'point_adjustment_page',
        'point_adjustment',
        'click_import_point_upload',
        'on click',
      )
      this.clearerror()
      let fileInputElement = this.$refs.file
      fileInputElement.click()
    },
    handleChange(event) {
      if (this.checkValidate(event.target.files[0])) {
        this.getFileUpdate = event.target.files[0]
        this.handleValidateSpending()
      }
    },
    countProgressTimer() {
      this.timeinterval = setInterval(() => {
        if (this.percentProgress >= 99) {
          this.percentProgress = 99
          clearInterval(this.timeinterval)
        }
        if (this.percentProgress >= 89) {
          clearInterval(this.timeinterval)
        } else {
          let math = Math.floor(Math.random() * 20)
          this.percentProgress += math
          if (this.percentProgress >= 99) {
            this.percentProgress = 99
            clearInterval(this.timeinterval)
          }
        }
      }, 1000)
    },
    onEdit(key) {
      console.log('onEdit : ', key)
      this.editableData = key
    },
    onSave() {
      this.editableData = null
    },
    onDelete(key) {
      console.log('onDelete : ', key)
    },
    btnBackUpload() {
      this.percentProgress = 0
      this.timeinterval = null
      this.isProgress = false
      this.isUploadSuccess = false
      this.countSuccess = 0
      this.countFailed = 0
      this.countTotal = 0
      this.data = []
      this.editableData = null
      this.$router.push({
        path: '/Import/AdjustSpending',
      })
    },
    handleValidateSpending() {
      this.isProgress = true
      this.countProgressTimer()
      let type = 'ContactNumber'
      if (this.importValue == 2) {
        type = 'UserId'
      } else {
        type = 'ContactNumber'
      }
      BzbsImport.uploadSpending(this.getFileUpdate, type, this.appId)
        .then(res => {
          var result = res.data
          if (result.success) {
            this.clearerror()
            this.disabledsubmit = false
            if (result.data.TotalCount == 0) {
              this.dispfile = false
              this.errData = true
              let errorDetail = this.lbl['err-miss-adjust-data']
              clearInterval(this.timeinterval)
              this.alertModalAction(
                this.lbl['alert-upload-error'],
                errorDetail,
                'error',
                true,
              )
            } else {
              this.excelFileName = result.data.FileName
              this.rowKey = result.data.RowKey
              this.partitionKey = result.data.PartitionKey
              this.dispfile = true
              this.percentProgress = 100
              clearInterval(this.timeinterval)
              this.handleSubmitValidate(this.rowKey, result.data)
            }
          } else {
            this.clearerror()
            let errorDetail = result.message
              ? result.message
              : this.lbl['err-miss-adjust-data']
            clearInterval(this.timeinterval)
            this.alertModalAction(
              this.lbl['alert-upload-error'],
              errorDetail,
              'error',
              true,
            )
          }
        })
        .catch(error => {
          console.log('error : ', error.response)
          let errorDetail = this.lbl['err-miss-adjust-data']
          clearInterval(this.timeinterval)
          this.alertModalAction(
            this.lbl['alert-upload-error'],
            errorDetail,
            'error',
            true,
          )
        })
    },
    handleSubmitValidate(rowKey, data) {
      console.log('Validate : ', rowKey, data)
      console.log('Validate : ', data)
      this.countSuccess = data.SuccessCount
      this.countFailed = data.FailCount
      this.countTotal = data.TotalCount
      this.data = data.Details
      if (data.FailCount > 0) {
        this.dataFailed = _.filter(data.Details, item => {
          if (item.Message != null) {
            return item
          }
        })
      } else {
        this.dataFailed = []
      }

      setTimeout(
        () => (
          (this.isUploadSuccess = true),
          this.$router.push({
            path: '/Import/AdjustSpending/' + rowKey,
          })
        ),
        2000,
      )
    },
    handleConfirmSubmit() {
      if (this.countFailed > 0) {
        this.confirmModalAction(
          this.lbl['confirm-message-import-include'],
          this.lbl['you-download-failed-list-import-again'],
          'warning',
          this.lbl['confirm-box-cancel-button'],
          true,
          () => {
            this.confirmModalSetting.isOpenModal = false
            this.handleSubmit()
          },
          () => {
            this.confirmModalSetting.isOpenModal = false
          },
        )
      } else {
        this.confirmModalAction(
          this.lbl['adjust-confirm-text'],
          this.lbl['point-adjust-after-click-yes'],
          'warning',
          this.lbl['confirm-box-cancel-button'],
          true,
          () => {
            this.confirmModalSetting.isOpenModal = false
            this.handleSubmit()
          },
          () => {
            this.confirmModalSetting.isOpenModal = false
          },
        )
      }
    },
    handleSubmit() {
      this.handleLoading(true)
      BzbsImport.confirmAdjustSpending(this.rowKey, this.partitionKey)
        .then(res => {
          this.handleLoading(false)
          if (res.data.success) {
            this.successupload = true
            let desciption = this.countSuccess + ' ' + this.lbl['members']
            this.alertModalAction(
              this.lbl['alert-box-success-header'],
              this.lbl['importusersuccesstext'],
              'success',
              true,
              desciption,
              true,
            )
          } else {
            this.alertModalAction(
              this.lbl['alert-box-error-header'],
              res.data.message
                ? res.data.message
                : this.lbl['Error occurred while processing your request.'],
              'error',
              true,
            )
          }
        })
        .catch(error => {
          this.handleLoading(false)
          this.alertModalAction(
            this.lbl['alert-box-error-header'],
            error.response.data.error.message
              ? error.response.data.error.message
              : this.lbl['Error occurred while processing your request.'],
            'error',
            true,
          )
        })
    },
    exprotFailed() {
      console.log('exprotFailed')
      this.handleLoading(true)
      Account.bzbsAnalyticTracking(
        'import_point_page',
        'import_point_details',
        'click_import_point_download',
        'on click',
      )

      let data = _.map(this.dataFailed, item => {
        let verify = 'mobile number'
        let verify_value = ''
        if (this.importValue == 2) {
          verify = 'UserId'
          verify_value = item.UserId
        } else {
          verify = 'mobile number'
          verify_value = item.ContactNumber
        }

        var spending = 'Spending'
        var description = 'Description'
        var remark = 'remark'

        return {
          [verify]: verify_value ? verify_value : '',
          [spending]: item.StrSpendingAmount ? item.StrSpendingAmount : '',
          [description]: item.Description ? item.Description : '',
          [remark]: item.Message ? item.Message : '',
        }
      })

      var dataWS = XLSX.utils.json_to_sheet(data, { dense: true })
      var wscols = [{ wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }]
      dataWS['!cols'] = wscols
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(
        wb,
        'CRMPlus_import_spending_' +
          moment(new Date()).format('YYYY-MM-DD') +
          '.xlsx',
      )

      this.handleLoading(false)
    },
    //#endregion Upload
  },
}
</script>

<style lang="scss">
@import '../ImportBatchPoint/AdjustPoint.scss';
</style>

<style lang="scss" scoped>
@import '../ImportBatchPoint/AdjustPointScoped.scss';

.disable-btn {
  opacity: 0.5;
}
.title-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
}
</style>
